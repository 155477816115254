.dashboardContent {
    background-color: var(--brightColor);
    min-height: 85vh;
    padding: 10vh;
}

.dashboardTop {
    height: 70px;
    background: var(--brightColor);
    box-shadow: 0 3px 6px black;
    opacity: 84%;
}

.dashboardTop > * {
    color: var(--darkColor);
}

.dashboardTopNavButton {
    width: 70px;
    height: 40px;
    background: var(--brightColor);
    border: 1px solid var(--darkColor);
    border-radius: 10px;
}

.faqButton {
    font-size: 16px;
    font-weight: bold;
}

.footerNav {
    color: var(--brightColor);
    font-size: 16px;
    font-weight: normal;
    height: 100%;
    opacity: 75%;
    padding-top: 0px;
    padding-bottom: 0px;
}

:root {
  --primaryColor: #0D6EFD;
  --brightColor: #EEF1F4;
  --darkColor: #212529;
  --themeColor: #0286A1;
}

/* .btn-primary,
.btn-primary:hover,
.btn-primary:active,*/
.btn:focus,
.btn:active {
  outline: none !important;
  /* box-shadow: none; */
  box-shadow: inset 0 0 0.2em;
}

.nav-link {
  color: #5b6188;
}

input[type="range"]::-webkit-slider-thumb {
  background: #5b6188;
}
input[type="range"]::-moz-range-thumb {
  background: #5b6188;
}
input[type="range"]::-ms-thumb {
  background: #5b6188;
}

.form-range {
  outline: none !important;
  box-shadow: none !important;
}

.form-check-input:active,
.form-check-input:focus,
.form-check-input {
  outline: none !important;
  box-shadow: none !important;
  border-color: #5b6188;
}
.form-check-input:checked {
  background-color: #5b6188;
}

.btn-primary,
.btn-primary:visited {
  background-color: #5b6188;
  border-color: #5b6188;
}
.btn-primary:hover {
  color: #000;
  background-color: #fff;
  border-color: #5b6188;
}

.outline-primary {
  color: #5b6188;
  background-color: transparent;
  background-image: none;
  border-color: #5b6188;
}

.btn-outline-primary,
.outline-secondary {
  color: #5b6188;
  background-color: transparent;
  background-image: none;
  border-color: #5b6188;
}

.btn-outline-primary:hover,
.outline-secondary:hover {
  color: #fff;
  background-color: #5b6188;
  border-color: #5b6188;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}
